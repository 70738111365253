import React, { Component } from 'react';
import './Hero.scss';

export default class Hero extends Component {
  render() {
    return (
      <div className="main-content__item text-white pb-2">
          <div className="img-block__1">
              <div className="img_block__1__text_holder text-center">
                  <h1>
                    "Копсис Транс" ГТП - Карлово
                  </h1>
              </div>
          </div>
      </div>
    );
  }
}
