import React, { Component } from 'react';


export default class Questions extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="main-content__item pt-2 pb-2 text-black">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-12">
                <div className="main_content__item__title">
                    <h2 className="text-center">Често задавани въпроси</h2>
                    <div className="title__border"></div>
                </div>
                <h6 className="text-center text-black">свързани с документацията или процедурата на провеждане на годишен технически преглед</h6>

                    <div className="accordion mb-2" id="accordionQuestions">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            Какви документи са необходими при технически преглед на МПС?
                        </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionQuestions">
                        <div className="accordion-body">
                            <ul>
                            <li>Свидетелство за регистрация на МПС - голям и малък талон</li>
                            <li>Документ за самоличност (лична карта или шофьорска книжка)</li>
                            <li>За монтирана газова уредба след 1.09.2014 г. се изисква валиден протокол за поставяне на уредбата за вида на горивото</li>
                            <li>Необходимо е да имате валидна сключена застраховка <code>„Гражданска отговорност“</code> и <code>платен данък за МПС</code>.</li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Кога се плаща данъкът на автомобила?
                        </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionQuestions">
                        <div className="accordion-body">
                            <p>При неплатен годишен данък за автомобила, проверката в техническия пункт се прекратява.</p>
                            <p>Данъкът за автомобила се плаща на 2 равни части:</p>
                            <ul>
                            <li>до 30 юни</li>
                            <li>до 31 октомври на съответната година, за която се дължи.</li>
                            </ul>
                            <p>След закупуване на кола, новият собственик заплаща дължимия данък за автомобила в двумесечен срок.</p>             
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Нужна ли е полица Гражданска отговорност при преминаване на ГТП?
                        </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionQuestions">
                        <div className="accordion-body">
                            <p>Наличието и валидността на задължителната застраховка</p>
                            <p>Гражданска отговорност се <code>проверява по електронен път</code>. Затова при извършване на годишен преглед не е необходимо предоставянето на хартия на полицата застраховка Гражданска отговорност на автомобилистите.</p>
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            Какъв е периодът и валидност на ГТП?
                        </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionQuestions">
                        <div className="accordion-body">
                            <p>ГТП е валиден до датата записана в талона за преглед.</p>
                            <p>Леките и товарни автомобили от категория N1 и М1 пр184,44,44еминават технически преглед преди изтичане на третата и петата година от датата на първоначалната им регистрация като нови, след което всяка година. Леките таксиметрови автомобили и превозните средства от категории M2 и М3 – на всеки 6 месеца. А мотори и мотоциклети и вече от 01.01.2023 подлежат на преглед и мотопеди – на всеки две години. Ремаркета от категория О1 - на всеки две години. Ремаркета от категория О2, О3, О4 - до 10 години минават преглед всяка година, а по стари от 10 години минават преглед на всеки 6 месеца. Категория N2 и N3 - до 10 години минават преглед всяка година, а по стари от 10 години минават преглед на всеки 6 месеца</p>
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            Какъв е рискът при шофиране с изтекъл ГТП?
                        </button>
                        </h2>
                        <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionQuestions">
                        <div className="accordion-body">
                            <p>В случай на шофиране на автомобил без валиден технически преглед, контролните органи имат право да налагат финансова санкция на стойност до 50 лв.</p>
                            <p>Липсата на валиден технически преглед на ППС може да Ви създаде проблеми във взаимоотношенията със застрахователите при пътно-транспортно произшествие, както и да е основание за отказ за изплащане на застрахователна щета.</p>
                        </div>
                        </div>
                    </div>
                    </div>

                </div>
            </div>
            </div>
        </div>
      </React.Fragment>
    );
  }
}