import React, { Component } from 'react';


export default class DocumentLinks extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="main-content__item pt-2 pb-2 text-black">
            <div className="container">
            <div className="main_content__item__title">
                <h2 className="text-center">Връзки за онлайн проверка на документи</h2>
                <div className="title__border"></div>
            </div>


            <div className="row align-items-center">

                <div className="col-12 col-lg-4 pb-2">
                <div className="info-holder d-flex gap-3 flex-column align-items-center text-center">
                    <div className="info_holder__icon">
                    <i className="bi bi-calendar fs-1"></i>
                    </div>
                    <div className="info_holder__text">
                    Поверете валидността на вашият технически преглед
                    </div>
                    <div className="info_holder__link">
                    <a href="https://rta.government.bg/services/check-inspection/index.html" target="_blank" className="text-decoration-none btn btn-secondary" rel="noreferrer">
                        Провери тук
                    </a>
                    </div>
                </div>
                </div>

                <div className="col-12 col-lg-4 pb-2">
                <div className="info-holder d-flex gap-3 flex-column align-items-center text-center">
                    <div className="info_holder__icon">
                    <i className="bi bi-car-front fs-1"></i>
                    </div>
                    <div className="info_holder__text">
                    Сключена застраховка - Гражданска отговорност
                    </div>
                    <div className="info_holder__link">
                    <a href="https://www2.guaranteefund.org/bg/%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%B5%D0%BD-%D1%86%D0%B5%D0%BD%D1%82%D1%8A%D1%80-%D0%B8-%D1%81%D0%BF%D1%80%D0%B0%D0%B2%D0%BA%D0%B8/%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8/%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D1%80%D0%BA%D0%B0-%D0%B7%D0%B0-%D0%B2%D0%B0%D0%BB%D0%B8%D0%B4%D0%BD%D0%B0-%D0%B7%D0%B0%D1%81%D1%82%D1%80%D0%B0%D1%85%D0%BE%D0%B2%D0%BA%D0%B0-%D0%B3%D1%80a%D0%B6%D0%B4a%D0%BD%D1%81%D0%BAa-%D0%BE%D1%82%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%D0%BD%D0%BE%D1%81%D1%82-%D0%BD%D0%B0-%D0%B0%D0%B2%D1%82%D0%BE%D0%BC%D0%BE%D0%B1%D0%B8%D0%BB%D0%B8%D1%81%D1%82%D0%B8%D1%82%D0%B5" target="_blank" className="text-decoration-none btn btn-secondary" rel="noreferrer">
                        Провери тук
                    </a>
                    </div>
                </div>
                </div>

                <div className="col-12 col-lg-4 pb-2">
                <div className="info-holder d-flex gap-3 flex-column align-items-center text-center">
                    <div className="info_holder__icon">
                    <i className="bi bi-sign-stop fs-1"></i>
                    </div>
                    <div className="info_holder__text">
                    Валидност на електронна винетка
                    </div>
                    <div className="info_holder__link">
                    <a href="https://check.bgtoll.bg/#/" target="_blank" className="text-decoration-none btn btn-secondary" rel="noreferrer">
                        Провери тук
                    </a>
                    </div>
                </div>
                </div>

            </div>
            </div>
        </div>
      </React.Fragment>
    );
  }
}