import React, { Component } from 'react';


export default class Footer extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="footer text-white">
        <div className="container p-4">
            <span className="fw-bold text-center small">2024 © Копсис Транс | Дизайн и разработка: TrippleDynamics</span>
        </div>
        </div>
      </React.Fragment>
    );
  }
}