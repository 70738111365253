import React, { Component } from 'react';
import DziLogo from '../../images/dzi_logo.png'

export default class Dzi extends Component {
  render() {
    return (
      <div className="main-content__item pt-5 pb-5 dzi_partner_holder text-white">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="diz_partner d-flex flex-column flex-lg-row gap-5 align-items-center justify-content-center">
                <div className="dzi_partner__logo">
                  <img className="img-fluid" src={DziLogo} alt="dzi logo"/>
                </div>
                <div className="diz_partner__info">
                  <h2>Официален партньор на ДЗИ</h2>
                  <p>ДЗИ е дружеството с най-дълъг опит на българския застрахователен пазар, част от белгийската банково-застрахователна група КВС.</p>
                  <p>Повече за ДЗИ може да прочетете в техният официален сайт <a className="text-decoration-none text-danger fw-bold" href="https://www.dzi.bg/" target="_blank" rel="noreferrer">ТУК</a></p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}