import './App.scss';
import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import Info from '../components/Info/Info';
import Dzi from '../components/Dzi/Dzi';
import CompanySwiper from '../components/CompanySwiper/CompanySwiper';
import GoogleMaps from '../components/GoogleMaps/GoogleMaps';
import Questions from '../components/Questions/Questions';
import DocumentLinks from '../components/DocumentsLinks/DocumentLinks';
import CompanyInfo from '../components/CompanyInfo/CompanyInfo';
import Footer from '../components/Footer/Footer';
import ReactGA from "react-ga4";
ReactGA.initialize("G-HD66F3J46S");

function App() {
  return (
    <div className="main_content">
      <Header/>
      <Hero/>
      <Info/>
      <Dzi/>
      <CompanySwiper/>
      <GoogleMaps/>
      <Questions/>
      <DocumentLinks/>
      <CompanyInfo/>
      <Footer/>
    </div>
  );
}

export default App;