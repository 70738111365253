import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import './Header.scss';
import Logo from './logo.png'
import {Link} from 'react-router-dom';

export default class Header extends Component {

  render() {
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <div className="d-flex gap-2 small">
            <i className="bi bi-calendar-week text-black"/>
              <p className="m-0 fw-bold text-black">
                Работно време: Пон – Пет: 8:00 – 17:00 | Съб: 8:00 – 13:30 | Нед: Почивен ден
              </p>
            </div>
          </div>
        </nav>

        <nav className="navbar navbar-expand-lg bg-primary" id="main-navbar">
          <div className="container-fluid">
            <div className="d-flex align-items-center justify-content-center w-100">
              <Link to="/"><img src={Logo} alt="Kopsistrans.bg - Logo" width="200" height="100"/></Link>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}