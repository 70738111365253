import React, { Component } from 'react';


export default class GoogleMaps extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="main-content__item pt-3 pb-3">
            <div className="container">
            <div className="row">
                <div className="col-12">
                <div className="main_content__item__title">
                    <h2 className="text-center">Google карта</h2>
                    <div className="title__border"></div>
                </div>
                <div className="ratio ratio-21x9">
                    <iframe title="Google maps" className="rounded" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2935.2136702907696!2d24.78763397648931!3d42.635630017646754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a997138cd10cb9%3A0xc10abf9078c88a9d!2zItCa0L7Qv9GB0LjRgSDQotGA0LDQvdGBIiDQk9Ci0J8!5e0!3m2!1sbg!2sbg!4v1709383532227!5m2!1sbg!2sbg" width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                </div>
            </div>
            </div>
        </div> 
      </React.Fragment>
    );
  }
}