import React, { Component } from 'react';
import Image from '../../images/gtp-main-image.jpg'

export default class Info extends Component {
  render() {
    return (
      <div className="main-content__item text-black pt-4 pb-2">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-lg-6">
              <h2 className="text-center">"Копсис Транс" ГТП</h2>
              <h6 className="text-center text-black">Пункт за технически преглед на автомобили в Карлово</h6>
              <hr className="text-kopsis"/>
              <div className="show-off-item d-flex flex-column flex-sm-row gap-2 align-items-start align-items-sm-center mb-2">
                <div className="show-off-item__title fw-bold d-flex gap-2 align-items-center">
                  <i className="bi bi-geo-alt main-color-icon fs-3"></i>
                  <p className="m-0 fw-bold">Адрес:</p>
                </div>
                <div className="show-off-item__value">
                  <a className="text-decoration-none" href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x40a997138cd10cb9:0xc10abf9078c88a9d?sa=X&ved=2ahUKEwjwqJWsytWEAxU4QfEDHR9QCZsQ4kB6BAgtEAA">местност Долно поделение, 4300 Карлово</a>
                </div>
              </div>
              <div className="show-off-item d-flex flex-column flex-sm-row gap-2 align-items-start align-items-sm-center mb-2">
                <div className="show-off-item__title fw-bold d-flex gap-2 align-items-center">
                  <i className="bi bi-telephone-fill main-color-icon fs-3"></i>
                  <p className="m-0 fw-bold">Телефон:</p>
                </div>
                <div className="show-off-item__value">
                  <a className="text-decoration-none" href="tel:0887595489">0887595489</a>
                </div>
              </div>
              <div className="show-off-item d-flex flex-column flex-sm-row gap-2 align-items-start mb-2">
                <div className="show-off-item__title fw-bold d-flex gap-2 align-items-center">
                  <i className="bi bi-calendar-week main-color-icon fs-3"></i>
                  <p className="m-0 fw-bold">Работно време:</p>
                </div>
                <div className="show-off-item__value">
                  <div className="d-flex flex-column">
                    <div className="show-off-item__multivalue">
                      Понеделник - Петък: <span className="fw-bold">8:00 - 17:00ч.</span>
                    </div>
                    <div className="show-off-item__multivalue">
                      Събота: <span className="fw-bold">8:00 – 13:30ч.</span>
                    </div>
                    <div className="show-off-item__multivalue">
                      Неделя: <span className="fw-bold text-danger">Почивен ден</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-12 col-lg-6 text-center">
              <img className="img-fluid rounded main-image" src={Image} alt="Hero"/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}