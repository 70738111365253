import React, { Component } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination   } from 'swiper/modules';

// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';


import DziLogo from '../../images/company_logos/dzi_logo.png'
import ArmeetzLogo from '../../images/company_logos/ArmeetzLogo.png'
import BulinsLogo from '../../images/company_logos/bulins_logo.png'
import BulstradLogo from '../../images/company_logos/bulstrad.png'
import EuroinsLogo from '../../images/company_logos/euroins_logo.png'
import GeneraliLogo from '../../images/company_logos/generali_logo.png'
import LevinsLogo from '../../images/company_logos/levins-logo-bg.png'
import LogoBg from '../../images/company_logos/logo_bg.png'

export default class CompanySwiper extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="main-content__item pt-5 pb-5 grajdanska-main-content">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="main_content__item__title">
                  <h2 className="text-center">При нас може да сключите и гражданска отговорност</h2>
                  <div className="title__border"></div>
                </div>

                <Swiper
                  spaceBetween={10}
                  slidesPerView={5}
                  modules= {[Navigation, Pagination]}
                  navigation = {{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                  }}
                  pagination={{ clickable: true, el: '.swiper-pagination' }}
                  speed={400}
                  breakpoints= {{
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 5
                    },
                    640: {
                      slidesPerView: 3,
                      spaceBetween: 5
                    },
                    991: {
                      slidesPerView: 4,
                      spaceBetween: 5
                    },
                    1200: {
                      slidesPerView: 5,
                      spaceBetween: 5
                    }
                  }}
                >
                  <SwiperSlide><div className="w-100 swiper-item"><a href='localhost:3000'><img className='img-fluid' src={DziLogo} alt='Amazon logo'/></a></div></SwiperSlide>
                  <SwiperSlide><div className="w-100 swiper-item"><a href='localhost:3000'><img className='img-fluid' src={ArmeetzLogo} alt='Amazon logo'/></a></div></SwiperSlide>
                  <SwiperSlide><div className="w-100 swiper-item"><a href='localhost:3000'><img className='img-fluid' src={BulinsLogo} alt='Amazon logo'/></a></div></SwiperSlide>
                  <SwiperSlide><div className="w-100 swiper-item"><a href='localhost:3000'><img className='img-fluid' src={BulstradLogo} alt='Amazon logo'/></a></div></SwiperSlide>
                  <SwiperSlide><div className="w-100 swiper-item"><a href='localhost:3000'><img className='img-fluid' src={EuroinsLogo} alt='Amazon logo'/></a></div></SwiperSlide>
                  <SwiperSlide><div className="w-100 swiper-item"><a href='localhost:3000'><img className='img-fluid' src={GeneraliLogo} alt='Amazon logo'/></a></div></SwiperSlide>
                  <SwiperSlide><div className="w-100 swiper-item"><a href='localhost:3000'><img className='img-fluid' src={LevinsLogo} alt='Amazon logo'/></a></div></SwiperSlide>
                  <SwiperSlide><div className="w-100 swiper-item"><a href='localhost:3000'><img className='img-fluid' src={LogoBg} alt='Amazon logo'/></a></div></SwiperSlide>
                
                  <div className="swiper-pagination"></div>
              
                  <div className="swiper-button-prev"></div>
                  <div className="swiper-button-next"></div>
                </Swiper>

                </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}