import React, { Component } from 'react';


export default class CompanyInfo extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="main-content__item pt-2 pb-2 text-black">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-12">
                <div className="main_content__item__title">
                    <h2 className="text-center">Информация за фирмата</h2>
                    <div className="title__border"></div>
                </div>
                <p><span className="fw-bold fst-italic">“Копсис-Транс” ООД</span> е правоприемник на <span className="fw-bold fst-italic">ЕТ “Ненко Чернооков”</span> и <span className="fw-bold fst-italic">ЕТ “Добрин Чамуков”</span>, които осъществяват транспортна дейност от 2000 г. Фирмата е член на Сдружението на автомобилните инженери в България.</p>
                <p>Името на фирмата произлиза от средновековния център на областта – Копсис. Водено от патриотични и родолюбиви чувства ръководството на фирмата дава своя принос за развитието и обогатяването на културно историческото наследство на карловския край.</p>
                <p><span className="fw-bold fst-italic">“Копсис–Транс”</span> ООД извършва годишни технически прегледи (ГТП) на всички видове ППС в собствен технически пункт и е сертифицирана по ISO 9001:2000.</p>
                </div>
            </div>
            </div>
        </div>
      </React.Fragment>
    );
  }
}